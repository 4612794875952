<template>
  <DivWrapper
    id="scanlots-popup"
    class="orderlot-popup active-pop"
    :className="color"
    v-if="message"
  >
    <Span className="icon-Btn close-pop" @handleClick="$emit('handleClick')">
      <close-icon />
    </Span>
    <p>{{ message }}</p>
  </DivWrapper>
</template>
<script>
import DivWrapper from "@/components/form/DivWrapper";
import CloseIcon from "@/components/svgIcons/CloseIcon";
import Span from "@/components/other/Span";
export default {
  components: {
    DivWrapper,
    CloseIcon,
    Span
  },
  props: {
    message: {
      required: true,
      type: String,
      default: ""
    },
    color: {
      type: String
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_modal-box.scss";
.red {
  background: #e02020 !important;
}
.green {
  background: #138808;
}
</style>
